import React from 'react'
import { P } from '@farewill/ui'
import { GTR } from '@farewill/ui/tokens'
import { RoleSection, RoleTemplate, RoleTitle, RoleUl } from '../RoleTemplate'

const AccountManager = (): React.JSX.Element => (
  <RoleTemplate
    roleName="Account Manager"
    roleUrl="https://dignity.kallidusrecruit.com/VacancyInformation.aspx?VId=2075"
    location="Haggerston, London (Hybrid flexible working)"
    salary="£39,000 (+15% - 30% Quartlery Commisisons) "
    contractType="Full-time, Permanent"
    closingDate="30th April 2025"
    content={
      <>
        <RoleSection>
          <RoleTitle>The Role</RoleTitle>
          <P>
            At Farewill, now part of the Dignity Funerals Group, we believe
            legacy giving has the power to shape the future of charities,
            providing long-term financial stability and helping them achieve
            their missions. Despite its potential, legacy giving is often
            underutilised—we’re here to change that.
          </P>
          <P>
            By partnering with incredible organisations, we make legacy giving
            accessible, impactful, and easy to navigate. Our work has already
            helped raise millions for charities, and we’re looking for a
            proactive and relationship-driven Account Manager to continue this
            success.
          </P>
          <P>
            As an Account Manager within our Partnerships team, you’ll play a
            key role in strengthening relationships with our mid- to high-value
            charity partners. Reporting to the Head of Partnerships, you’ll help
            charities maximise their fundraising potential through our free
            will-writing programme, ensuring every campaign achieves maximum
            impact.
          </P>
          <P>
            This is a strategic, relationship-focused role where you’ll work
            closely with charity partners to unlock growth opportunities,
            maintain long-term success, and drive Farewill’s mission to
            transform the legacy-giving landscape.
          </P>
        </RoleSection>
        <RoleSection margin={[GTR.M, 0]}>
          <RoleTitle>What the day-to-day will look like for you:</RoleTitle>
          <RoleUl>
            <li>
              Leading client meetings and fostering strong, collaborative
              relationships, ensuring clear communication and mutual
              understanding.
            </li>
            <li>
              Developing tailored growth strategies to help partners maximise
              their impact. Working closely with each charity partner to assess
              their unique needs and goals, using your insights and
              understanding of their objectives and designing custom growth
              strategies that will optimise their fundraising potential.
            </li>
            <li>
              Managing and optimising free will-writing campaigns to drive
              results: Overseeing the implementation of our free will-writing
              programme, ensuring it runs smoothly and effectively for each
              partner.
            </li>
            <li>
              Identifying opportunities for growth and moving partners to
              higher-value packages: As you build trust and deepen your
              relationships with partners, you will identify opportunities for
              growth—whether it’s expanding existing campaigns, introducing new
              products, or upgrading partners to higher-value packages.
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>We are looking for someone who has:</RoleTitle>
          <RoleUl>
            <li>
              A passion for supporting charities and making a real impact.
            </li>
            <li>
              Experience in account management or within the charity sector.
            </li>
            <li>
              Strong communication skills with a talent for
              relationship-building.
            </li>
            <li>
              A keen eye for detail, ensuring all interactions are accurate and
              meaningful.
            </li>
            <li>
              A proactive, innovative mindset—someone who can identify
              opportunities, take ownership, and drive results.
            </li>
            <li>
              A collaborative team player who puts customers first and works
              with urgency and focus.
            </li>
          </RoleUl>
        </RoleSection>
        <RoleSection>
          <RoleTitle>What we offer</RoleTitle>
          <RoleUl>
            <li>
              Annual salary of £39,000 + quartlery performance based commissions
            </li>
            <li>25 Days Holiday + Bank Holidays</li>
            <li>BUPA Healthcare (Self)</li>
            <li>X2 Life Assurance</li>
            <li>Pension Scheme</li>
            <li>Hybrid flexible working</li>
            <li>Access to our internal colleague development schemes</li>
          </RoleUl>
        </RoleSection>
      </>
    }
  />
)

export default AccountManager
